import Link from 'next/link'
import React, { useContext, useMemo, useState } from 'react'
import { LogoWhite } from '../../icons/LogoWhite'
import { Menu, Transition } from '@headlessui/react'
import { Card } from '../../designsystem/Card'
import { LogoDark } from '../../icons/LogoDark'
import cn from 'classnames'
import { useRouter } from 'next/router'
import EdgeCachingIcon from 'icons/EdgeCachingIcon'
import MetricsIcon from 'icons/MetricsIcon'
import RateLimitingIcon from 'icons/RateLimitingIcon'
import { useUserContext } from 'components/UserProvider'
import { NavigationContext } from '../../pages/_app'
import { FEATURE_RATE_LIMITING, USE_CASES_SLUG } from 'common/constants'
import { CaseStudyGroup } from 'types/generated/contentful'
import IconRenderer, { Icon } from './IconRenderer'
import CaseStudyCard from './CaseStudyCard'
import LinkArrow from '../../icons/LinkArrow'
import Dollars from '../../icons/Dollars'
import Stability from '../../icons/Stability'
import Shield from '../../icons/Shield'
import Thunder from '../../icons/Thunder'
import { FEATURE_EDGE_CACHING, FEATURE_METRICS } from 'common/constants'

type HeaderProps = {
  dark?: boolean
}

type MobileSubmenuLinks = {
  label: string
  links: CaseStudyGroup[]
}[]

export const Header = ({ dark = false }: HeaderProps): JSX.Element => {
  const { user } = useUserContext()
  const router = useRouter()
  const [visibleModal, setVisibleModal] = useState<
    'features' | 'use-cases' | 'solutions' | 'none'
  >('none')
  const navigation = useContext(NavigationContext)
  const iconClasses = 'w-11 h-auto flex-shrink-0'

  const useCases: MobileSubmenuLinks = useMemo(() => {
    const { industryUseCases, technologyUseCases } = navigation
    // Industry use cases
    const industrySlugOrder = ['ecommerce', 'news-media', 'blockchain-web3']

    const sortedIndustryUseCases = [
      industrySlugOrder.map((slug) =>
        industryUseCases.find((industry) => industry?.slug === slug),
      ),

      industryUseCases.filter((industry) => {
        return industry.slug && !industrySlugOrder.includes(industry?.slug)
      }),
    ]
      .flat()
      .filter((item): item is CaseStudyGroup => !!item) // type guard

    // Technology use cases
    const technologySlugOrder = ['wpgraphql', 'hasura']

    const sortedTechnologyUseCases = [
      technologySlugOrder.map((slug) =>
        technologyUseCases.find((industry) => industry?.slug === slug),
      ),

      technologyUseCases.filter((industry) => {
        return industry.slug && !technologySlugOrder.includes(industry?.slug)
      }),
    ]
      .flat()
      .filter((item): item is CaseStudyGroup => !!item) // type guard

    return [
      {
        label: 'Industries',
        links: sortedIndustryUseCases,
      },
      { label: 'Technologies', links: sortedTechnologyUseCases },
    ]
  }, [navigation])

  return (
    <Menu>
      {({ open }) => (
        <header className='marketing-page relative py-5 z-10'>
          <div className='max-w-7xl px-6 mx-auto'>
            <nav className='relative flex items-center justify-between lg:justify-center'>
              <div className='flex items-center flex-1'>
                <div className='flex items-center justify-between w-full lg:w-auto'>
                  <div className='flex items-center space-x-3 xs:space-x-4'>
                    <Link href='/' passHref legacyBehavior>
                      <a
                        className={cn('transition-opacity', {
                          'pointer-events-none': router.asPath === '/',
                          'hover:opacity-70 focus:opacity-70':
                            router.asPath !== '/',
                        })}
                        onFocus={() => setVisibleModal('none')}
                        tabIndex={router.asPath === '/' ? -1 : 0}
                        aria-label='Home'
                      >
                        {dark ? (
                          <LogoWhite
                            className='h-8 w-auto'
                            width={138}
                            height={38}
                          />
                        ) : (
                          <LogoDark
                            className='h-8 w-auto'
                            width={138}
                            height={38}
                          />
                        )}
                      </a>
                    </Link>
                    {/* <WeAreHiringTag dark={dark} /> */}
                  </div>
                  <div className='flex items-center lg:hidden'>
                    <Menu.Button
                      className={cn(
                        'min-clickable-area inline-flex items-center justify-center transition duration-150 ease-in-out rounded-md focus:outline-none',
                        {
                          ' text-neutral-60 focus:bg-neutral-10 focus:text-neutral-40 hover:bg-neutral-10 hover:text-neutral-40':
                            !dark,
                          'text-white opacity-60': dark,
                        },
                      )}
                      aria-label='Main menu'
                      aria-haspopup='true'
                    >
                      <Hamburger />
                    </Menu.Button>
                  </div>
                </div>
              </div>
              <div className='hidden lg:flex lg:items-center lg:justify-end'>
                <div className='hidden lg:flex lg:space-x-7 xl:space-x-10'>
                  <ModalMenu
                    groupName='Features'
                    visible={visibleModal === 'features'}
                    setVisibility={setVisibleModal}
                    modalName='features'
                    dark={dark}
                    className='flex flex-col space-y-2 p-5 min-w-120'
                  >
                    <FeatureLink
                      href='/graphql-edge-caching'
                      onFocus={() => setVisibleModal('features')}
                      icon={
                        <EdgeCachingIcon
                          context='features-modal'
                          className={iconClasses}
                        />
                      }
                      feature={FEATURE_EDGE_CACHING}
                      description='Reduce origin traffic and boost performance by caching GraphQL queries.'
                    />
                    <FeatureLink
                      href='/graphql-metrics'
                      onFocus={() => setVisibleModal('features')}
                      icon={
                        <MetricsIcon
                          context='features-modal'
                          className={iconClasses}
                        />
                      }
                      feature={FEATURE_METRICS}
                      description={`With no configuration, get real-time observability for your GraphQL APIs usage, performance and errors.`}
                    />
                    <FeatureLink
                      href='/graphql-security'
                      onFocus={() => setVisibleModal('features')}
                      icon={
                        <RateLimitingIcon
                          context='features-modal'
                          className={iconClasses}
                        />
                      }
                      feature={FEATURE_RATE_LIMITING}
                      description={`Protect your GraphQL API from scrapers, traffic or infrastructure costs spikes, and broken SLA terms.`}
                    />
                  </ModalMenu>

                  <ModalMenu
                    groupName='Solutions'
                    visible={visibleModal === 'solutions'}
                    setVisibility={setVisibleModal}
                    modalName='solutions'
                    dark={dark}
                    className='flex flex-col space-y-2 p-5 min-w-64'
                  >
                    <ul className='-mx-2 -my-2'>
                      <li>
                        <Link
                          href='/solutions/reduce-costs'
                          className='flex items-center gap-3 p-2 rounded transition-colors hover:bg-neutral-7 focus:bg-neutral-7'
                          onFocus={() => setVisibleModal('solutions')}
                        >
                          <Dollars className='w-8 h-8 text-purple' />
                          Reduce costs
                        </Link>
                      </li>
                      <li>
                        <Link
                          href='/solutions/improve-stability'
                          className='flex items-center gap-3 p-2 rounded transition-colors hover:bg-neutral-7 focus:bg-neutral-7'
                          onFocus={() => setVisibleModal('solutions')}
                        >
                          <Stability className='w-8 h-8 text-purple' />
                          Improve Stability
                        </Link>
                      </li>
                      <li>
                        <Link
                          href='/solutions/secure-your-api'
                          className='flex items-center gap-3 p-2 rounded transition-colors hover:bg-neutral-7 focus:bg-neutral-7'
                          onFocus={() => setVisibleModal('solutions')}
                        >
                          <Shield className='w-8 h-8 text-purple' />
                          Secure Your API
                        </Link>
                      </li>
                      <li>
                        <Link
                          href='/solutions/boost-performance'
                          className='flex items-center gap-3 p-2 rounded transition-colors hover:bg-neutral-7 focus:bg-neutral-7'
                          onFocus={() => setVisibleModal('solutions')}
                        >
                          <Thunder className='w-8 h-8 text-purple' />
                          Boost Performance
                        </Link>
                      </li>
                    </ul>
                  </ModalMenu>

                  {/* Use cases */}
                  {navigation && (
                    <ModalMenu
                      groupName='Case studies'
                      visible={visibleModal === 'use-cases'}
                      setVisibility={setVisibleModal}
                      modalName='use-cases'
                      dark={dark}
                      className='flex space-x-8 w-max-content'
                    >
                      {useCases.map(({ label, links }, index) => {
                        return (
                          <div
                            className={`${index === 0 ? 'pl-10' : ''} py-10`}
                            key={label}
                          >
                            <span className='label opacity-50'>{label}</span>
                            <ul className='spacer-xs space-y-2'>
                              {links &&
                                links.map((link) => {
                                  const { slug } = link

                                  return (
                                    <li key={slug}>
                                      <UseCaseLink
                                        useCase={link}
                                        onFocus={() =>
                                          setVisibleModal('use-cases')
                                        }
                                      />
                                    </li>
                                  )
                                })}
                            </ul>
                          </div>
                        )
                      })}

                      {navigation.latestUseCase && (
                        <div className='border-l-neutral-15 border-l-[1px]'>
                          <CaseStudyCard
                            caseStudy={navigation.latestUseCase}
                            anchorOnFocus={() => setVisibleModal('use-cases')}
                            noShadow={true}
                            orientation='vertical'
                            hoverable={false}
                            className='min-w-[240px]'
                          />
                        </div>
                      )}
                    </ModalMenu>
                  )}

                  <NavLink dark={dark} href='/pricing'>
                    Pricing
                  </NavLink>
                  <NavLink dark={dark} href='/enterprise'>
                    Enterprise
                  </NavLink>
                  <NavLink dark={dark} href='/docs'>
                    Docs
                  </NavLink>
                  <NavLink dark={dark} href='/blog'>
                    Blog
                  </NavLink>
                  <NavLink
                    dark={dark}
                    href='https://savvycal.com/stellate/demo'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Talk to an expert
                  </NavLink>
                  <div
                    className={cn('border-l-2', {
                      'border-neutral-15': !dark,
                      'border-white opacity-50': dark,
                    })}
                  />
                  {user ? (
                    <NavLink dark={dark} href='/app'>
                      Dashboard
                    </NavLink>
                  ) : (
                    <>
                      <NavLink dark={dark} href='/login'>
                        Login
                      </NavLink>
                      <NavLink dark={dark} href='/signup'>
                        Sign up
                      </NavLink>
                    </>
                  )}
                </div>
              </div>
            </nav>
          </div>
          <Transition
            show={open}
            enter='transition ease-out duration-100'
            enterFrom='transform opacity-0 scale-95'
            enterTo='transform opacity-100 scale-100'
            leave='transition ease-in duration-75'
            leaveFrom='transform opacity-100 scale-100'
            leaveTo='transform opacity-0 scale-95'
          >
            <Menu.Items
              static
              className='absolute inset-x-0 -top-14 right-0 z-10 px-3 py-2 origin-top-right outline-none'
            >
              <div className='rounded-lg shadow-md'>
                <div
                  className='overflow-hidden bg-white rounded-lg shadow-xs'
                  role='menu'
                  aria-orientation='vertical'
                  aria-labelledby='main-menu'
                >
                  <div className='flex items-center justify-between px-5 pt-4'>
                    <div className='flex items-center space-x-3 xs:space-x-4'>
                      <Link href='/' passHref>
                        <Menu.Button>
                          <LogoDark className='w-auto h-8' />
                        </Menu.Button>
                      </Link>
                      {/*<WeAreHiringTag dark={false} />*/}
                    </div>
                    <div className='-mr-2'>
                      <Menu.Button className='min-clickable-area inline-flex items-center justify-center p-2 transition-colors rounded-md outline-none text-neutral-60 hover:text-neutral-25 focus:text-neutral-25 hover:bg-neutral-10 focus:bg-neutral-10 focus:outline-none'>
                        <Hamburger />
                      </Menu.Button>
                    </div>
                  </div>
                  <div className='px-2 pb-3 relative'>
                    <div className='py-6 space-y-2'>
                      <MobileLink href='/graphql-edge-caching'>
                        <EdgeCachingIcon
                          context='mobile-menu'
                          className='w-6 h-auto mr-2 flex-shrink-0'
                        />
                        {FEATURE_EDGE_CACHING}
                      </MobileLink>
                      <MobileLink href='/graphql-metrics'>
                        <MetricsIcon
                          context='mobile-menu'
                          className='w-6 h-auto mr-2 flex-shrink-0'
                        />
                        {FEATURE_METRICS}
                      </MobileLink>
                      <MobileLink href='/graphql-security'>
                        <RateLimitingIcon
                          context='mobile-menu'
                          className='w-6 h-auto mr-2 flex-shrink-0'
                        />
                        {FEATURE_RATE_LIMITING}
                      </MobileLink>
                    </div>

                    <div className='space-y-2'>
                      <MobileSubmenu label='Solutions'>
                        <ul>
                          <li>
                            <Link
                              href='/solutions/improve-stability'
                              className='flex items-center gap-3 p-2 font-demibold rounded transition-colors hover:bg-neutral-7 focus:bg-neutral-7'
                            >
                              <Dollars className='w-7 h-7 text-purple' />
                              Reduce costs
                            </Link>
                          </li>
                          <li>
                            <Link
                              href='/solutions/reduce-costs'
                              className='flex items-center gap-3 p-2 font-demibold rounded transition-colors hover:bg-neutral-7 focus:bg-neutral-7'
                            >
                              <Stability className='w-7 h-7 text-purple' />
                              Improve Stability
                            </Link>
                          </li>
                          <li>
                            <Link
                              href='/solutions/secure-your-api'
                              className='flex items-center gap-3 p-2 font-demibold rounded transition-colors hover:bg-neutral-7 focus:bg-neutral-7'
                            >
                              <Shield className='w-7 h-7 text-purple' />
                              Secure Your API
                            </Link>
                          </li>
                          <li>
                            <Link
                              href='/solutions/boost-performance'
                              className='flex items-center gap-3 p-2 font-demibold rounded transition-colors hover:bg-neutral-7 focus:bg-neutral-7'
                            >
                              <Thunder className='w-7 h-7 text-purple' />
                              Boost Performance
                            </Link>
                          </li>
                        </ul>
                      </MobileSubmenu>

                      <MobileSubmenu label='Case studies'>
                        <div className='pl-3 pt-4 pb-8 flex flex-col space-y-8'>
                          {useCases.map(({ label, links }) => {
                            return (
                              <div key={label}>
                                <span className='label opacity-50'>
                                  {label}
                                </span>

                                <ul className='spacer-2xs space-y-2'>
                                  {links &&
                                    links.map((industry) => {
                                      const { slug } = industry

                                      return (
                                        <li key={slug}>
                                          <UseCaseLink
                                            useCase={industry}
                                            mobile
                                          />
                                        </li>
                                      )
                                    })}
                                </ul>
                              </div>
                            )
                          })}
                        </div>
                      </MobileSubmenu>

                      <MobileLink href='/pricing'>Pricing</MobileLink>
                      <MobileLink href='/enterprise'>Enterprise</MobileLink>
                      <MobileLink href='/docs'>Docs</MobileLink>
                      <MobileLink href='/blog'>Blog</MobileLink>
                      {user ? (
                        <MobileLink href='/app'>Dashboard</MobileLink>
                      ) : (
                        <>
                          <MobileLink href='/login'>Login</MobileLink>
                          <MobileLink href='/signup'>Sign up</MobileLink>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Menu.Items>
          </Transition>
        </header>
      )}
    </Menu>
  )
}

const NavLink = ({
  href,
  children,
  dark,
  target,
  rel,
  ...props
}: {
  href: string
  children: React.ReactNode
  dark: boolean
  target?: string
  rel?: string
}) => {
  const router = useRouter()
  const isActive = href === router.asPath

  return (
    <Link passHref legacyBehavior href={href} {...props}>
      <a
        target={target}
        rel={rel}
        className={cn(
          'min-clickable-area inline-flex items-center font-demibold transition-colors-opacity text-15',
          {
            'text-white opacity-85 hover:opacity-100 focus:opacity-100': dark,
            'text-white pointer-events-none': dark && isActive,
            'text-neutral hover:text-neutral-110 focus:text-neutral-110':
              !dark && !isActive,
            'text-neutral-110 pointer-events-none': !dark && isActive,
          },
        )}
        tabIndex={isActive ? -1 : 0}
      >
        {children}
      </a>
    </Link>
  )
}

const MobileLink = ({ href, children, ...props }) => {
  return (
    <Link passHref legacyBehavior href={href} {...props}>
      <a className='mobile-menu-link' role='menuitem'>
        {children}
      </a>
    </Link>
  )
}

const Hamburger = (props) => {
  return (
    <svg
      width={24}
      height={26}
      stroke='currentColor'
      viewBox='0 0 24 26'
      fill='none'
      className='w-6 h-6'
      {...props}
    >
      <path strokeWidth={2} d='M0 1L24 1' />
      <path strokeWidth={2} d='M0 13L24 13' />
      <path strokeWidth={2} d='M0 25L24 25' />
    </svg>
  )
}

const ModalMenu = ({
  groupName,
  visible,
  setVisibility,
  modalName,
  dark,
  children,
  className,
  ...props
}) => (
  <div
    className='min-clickable-area large cursor-pointer font-demibold after:h-14'
    onMouseEnter={() => setVisibility(modalName)}
    onMouseLeave={() => setVisibility('none')}
    onBlur={() => setVisibility('none')}
    aria-label={`Open ${groupName}' menu`}
    aria-haspopup='true'
    aria-expanded={visible}
    aria-pressed={visible}
    tabIndex={-1}
    {...props}
  >
    <span
      className={cn('transition-colors-opacity text-15', {
        'text-white opacity-85 hover:opacity-100 focus:opacity-100':
          dark && !visible,
        'text-white pointer-events-none': dark && visible,
        'text-neutral hover:text-neutral-110 focus:text-neutral-110':
          !dark && !visible,
        'text-neutral-110 pointer-events-none': !dark && visible,
      })}
    >
      {groupName}
    </span>

    <Card
      noPadding
      noMaxWidth
      className={`absolute cursor-auto z-10 text-left left-1/2 transform -translate-x-1/2 translate-y-2 transition-opacity duration-[400ms] modal-menu-safearea ${
        !visible ? 'opacity-0 pointer-events-none' : 'opacity-100'
      } ${className ? className : ''}`}
    >
      {children}
    </Card>
  </div>
)

const FeatureLink = ({
  href,
  onFocus,
  icon,
  feature,
  description,
  hint = '',
  ...props
}) => (
  <Link passHref legacyBehavior href={href} {...props}>
    <a
      onFocus={onFocus}
      className='flex items-start space-x-5 p-5 transition-colors rounded-md hover:bg-neutral-7 focus:bg-neutral-7 focus:outline-none'
    >
      {icon}
      <div className='flex flex-col text-neutral'>
        <span>
          {feature}
          {hint && (
            <span className='ml-2 px-1.5 py-1 border border-neutral-25 rounded-6 font-demibold text-15'>
              {hint}
            </span>
          )}
        </span>
        <span className='small-body-text mt-1 text-neutral-60'>
          {description}
        </span>
      </div>
    </a>
  </Link>
)

const UseCaseLink = ({
  useCase,
  mobile,
  onFocus,
}: {
  useCase: CaseStudyGroup
  mobile?: boolean
  onFocus?: () => void
}) => {
  const { type, slug, title } = useCase

  return (
    <Link legacyBehavior href={`${USE_CASES_SLUG}/${type}/${slug}`}>
      <a
        onFocus={onFocus}
        className={
          'flex items-center space-x-3 -ml-2 py-2 pl-2 pr-6 rounded-[4px] flex-grow transition-colors hover:bg-neutral-7 focus:bg-neutral-7'
        }
      >
        <IconRenderer
          icon={slug as Icon}
          className={`${mobile ? 'h-7' : 'h-8'} w-auto ${
            type === 'industry' ? 'text-red' : 'text-magenta'
          }`}
        />

        <span className={`font-demibold ${mobile ? 'text-base' : ''}`}>
          {title}
        </span>
      </a>
    </Link>
  )
}

const MobileSubmenu = ({
  label,
  children,
}: {
  label: string
  children: React.ReactNode
}) => {
  const [visible, setVisibility] = useState(false)

  return (
    <>
      <button onClick={() => setVisibility(true)} className='mobile-menu-link'>
        {label}
        <LinkArrow className='h-2.5 w-auto text-neutral-40 stroke-current transform ml-2 mt-[2px]' />
      </button>

      <div
        className={`absolute overflow-auto top-0 left-0 px-2 pb-3 transition-transform z-10 bg-white w-full h-full ${
          visible ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        <button
          onClick={() => setVisibility(false)}
          className='mobile-menu-link mt-8'
        >
          <LinkArrow className='h-2.5 w-auto text-neutral-40 stroke-current transform rotate-180 mr-2' />
          {label}
        </button>

        {children}
      </div>
    </>
  )
}
